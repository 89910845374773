/*global wsProtocol, wsHost, customUserId, experimentId*/
/*eslint no-undef: "error"*/

import { setCompletedAt, setFailedAt, setStartedAt, setResetAt } from './tasks.js';

export default function connect() {
  var socket = new WebSocket(wsProtocol +'://' + wsHost + '/wss/admin_tasks/');
  $("body").css("cursor", "wait");

  socket.onopen = function() {
    socket.send(JSON.stringify({
      'custom_user_id': customUserId,
      'experiment_id': experimentId
    }));
  };

  socket.onmessage = function(event) {
    let data = JSON.parse(event.data);
    $.each(data.tasks, function(taskId, task) {
      if ($('#' + taskId).length == 0) {
        location.reload();
      }
      $('#started_at-' + taskId).text(task.started_at);
      if (task.state == 'completed') {
        setCompletedAt(task);
      } else if (task.state == 'not_completed') {
        setFailedAt(task);
      } else if (task.state == 'started') {
        setStartedAt(task);
      } else if (task.state == 'reset') {
        setResetAt(task);
      }
      if (['paid', 'received'].includes(task.payment.state)) {
        if (task.state == "completed") {
          setCompletedAt(task);
        } else if (task.state == "failed") {
          setFailedAt(task);
        }
      }
      $('#duration-' + taskId).text(task.duration);
      $('#base_payment-' + taskId).text(task.base_payment);
      $('#bonus-' + taskId).text(task.bonus);
      let total_payment = task.state == 'reset' ? '<span style="color: #ffc107;">' + task.payment.amount + '</span>' : task.payment.amount;
      $('#total_payment-' + taskId).html(total_payment);
      $('#payment-done-at-' + taskId).text(task.payment.paid_at);
      if (['paid', 'received'].includes(task.payment.state)) {
        $('#payment-done-at-' + taskId).html('<span style="cursor: pointer; font-size: large; font-weight: bold; color: green;">✓</span><br>' + task.payment.paid_at);
      }
      if (task.payment.state == 'received') {
        $('#payment-received-at-' + taskId).html('<span style="cursor: pointer; font-size: large; font-weight: bold; color: green;">✓</span><br>' + task.payment.received_at);
      }
      if (['completed', 'not_completed', 'reset'].includes(task.state) && task.payment.state == "unpaid" && task.total_payment_as_number > 0) {
        $('#payment_done-' + taskId).show();
      } else {
        $('#payment_done-' + taskId).hide();
      }
      if (task.state == 'not_completed') {
        $('#reset-task-' + taskId).show();
      }
      $("body").css("cursor", "default");
    });

    $('#total-base-payments').text(data['total_base_payments']);
    $('#total-bonuses').text(data['total_bonuses']);
    $('#total-payments').text(data['total_payments']);

    socket.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect in one second.', e.reason);
      setTimeout(function() {
        connect();
      }, 1000);
    };

    socket.onerror = function(err) {
      console.error('Socket error: ', err.message, 'Closing socket.');
      socket.close();
    };
  };

  $('.pay-task').unbind('click').click(function() {
    window.location = '/tasks/pay/' + $(this).data('task-id') + '?redirect_to=experiment';
  });

  $('.btn-reset').on('click', function() {
    let taskId = $(this).data("task-id");
    $("#reset-task").data("task-id", taskId);
  });

  $('#reset-task').on('click', function() {
    let taskId = $(this).data("task-id");
    $.ajax({
      type: 'GET',
      url: "/tasks/" + taskId + "/reset",
      success: function () {
        console.log("Task(" + taskId + ") reset.");
        window.location.reload();
      },
      error: function (response) {
        console.log("There was an error resetting Task(" + taskId + "):");
        console.log(response);
      }
    });
  });
}
